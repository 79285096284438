@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular */
@font-face {
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* DemiBold */
@font-face {
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Heavy */
@font-face {
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: "AvenirNext", sans-serif;
}

.radi {
  background: linear-gradient(to top right, #2196F3, #9333EA, #A557EE, #FFFFFF)
}

.radiNoWhite {
  background: linear-gradient(to top right, #2196F3, #9333EA)
}